import { Helmet } from 'react-helmet'
import LeaderboardComponent from 'components/Leaderboard/LeaderboardComponent'

const LeaderBoard = () => {

    return (
        <>
            <Helmet>
                <title>Leaderboard : BlockBolt Winsome Quest!</title>
            </Helmet>
            <LeaderboardComponent />
        </>
    )
}

export default LeaderBoard