import { Image } from "antd";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import CatLogo from "assets/img/cat_404.gif";
import { BBLogo } from "assets/svg";
import ButtonComponent from "components/FormsComponent/ButtonComponent";
import { useStyles } from "./style";

const NotRecognize = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classNames(classes.errorPageBody)}>
      <div className="errorPageBg">404</div>
      <div className="errorPageInfo  dFlex alignCenter justifyCenter flexCol">
        <div className="logo mb30"><BBLogo /></div>
        <Image src={CatLogo} alt="cat-gif" className="catGif mt30" preview={false} />
        <p className="LgText mt30 mb5">
          <span className="XLgText">Oops!</span> This page doesn't exist.
        </p>
        <span className="MdText mb10">Please go back to the homepage.</span>
      </div>
      <ButtonComponent yellowBtn className="mt30" onClick={() => history.push('/')}>
        Back to Quest
      </ButtonComponent>
    </div>
  );
};
export default NotRecognize;
