import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LeaderboardIcon } from "assets/svg";
import FooterComponent from "components/Layout/FooterComponent";
import HeaderComponent from "components/Layout/HeaderComponent";
import TextBoxComponent from "components/FormsComponent/TextBoxComponent";
import ButtonComponent from "components/FormsComponent/ButtonComponent";
import SelectComponent from "components/FormsComponent/SelectComponent";
import LeaderlistComponent from "../LeaderlistComponent";
import "./style.css";

const LeaderboardComponent = () => {
  const defaultValue = "All";
  const nftlist = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Karrier Pigeons",
      label: "Karrier Pigeons",
    },
    {
      value: "Sacabam",
      label: "Sacabam",
    },
    {
      value: "Novagen",
      label: "Novagen",
    },
  ];

  const [currentNftName, setCurrentNftName] = useState("All");
  const [walletAddress, setWalletAddress] = useState("");
  const history = useHistory();

  const handleChange = (value) => {
    setCurrentNftName(value);
  };

  return (
    <>
      <ToastContainer />
      <main className="mainSection">
        <div className="container">
          <div className="mainSectionbody">
            <HeaderComponent />
            <div className="mt50 leaderboardWrapper">
              <div className="dFlex alignCenter justifyBetween leaderboardHeader">
                <p className="LgText fontBold textUpper yellowGradient dFlex gap10 alignCenter">
                  <span>
                    <LeaderboardIcon />
                  </span>
                  Winsome Quest Leaderboard
                </p>
                <SelectComponent
                  defaultValue={defaultValue}
                  options={nftlist}
                  handleChange={handleChange}
                />
                <div className="dFlex  gap10">
                  <TextBoxComponent
                    type="text"
                    title="name"
                    placeholder="Search wallet address"
                    mandatory
                    name="name"
                    value={walletAddress}
                    onChange={(e) => setWalletAddress(e.target.value)}
                    className="m0"
                  />
                  <ButtonComponent
                    btnLight
                    onClick={() => setWalletAddress("")}
                  >
                    Clear
                  </ButtonComponent>
                </div>
              </div>
              <div className="leaderboardBody">
                <LeaderlistComponent walletAddress={walletAddress} currentNftName={currentNftName} />
              </div>
              <div className="mt20 textRight">
                <ButtonComponent
                  className=""
                  yellowBtn
                  onClick={() => history.push("/")}
                >
                  Back
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterComponent />
    </>
  );
};

export default LeaderboardComponent;
