import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ArrowTilt, CopyIcon } from "assets/svg";
import { getQuestOrders } from "apiServices/app";
import { copyContent, explorerAccountURL, shorten_address } from "utils/helper";
import TableComponent from "components/FormsComponent/TableComponent";
import { columns, raw_data } from "./constant";

const LeaderlistComponent = ({ walletAddress, currentNftName }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [questListing, setQuestListing] = useState([]);
  const [karrierListing, setKarrierListing] = useState([]);
  const [sacabamListing, setSacabamListing] = useState([]);
  const [novagenListing, setNovagenListing] = useState([]);

  function combineOrders(data) {
    const orderMap = new Map();
    data.forEach(item => {
      const { client_address, order_count } = item;
      if (orderMap.has(client_address)) {
        orderMap.set(client_address, orderMap.get(client_address) + order_count);
      } else {
        orderMap.set(client_address, order_count);
      }
    });
    return Array.from(orderMap, ([client_address, order_count]) => ({ client_address, order_count }));
  }

  const selectDataByNftName = () => {
    switch (currentNftName) {
      case "Karrier Pigeons":
        return karrierListing;
      case "Sacabam":
        return sacabamListing;
      case "Novagen":
        return novagenListing;
      default:
        return questListing;
    }
  };

  const getFilteredData = () => {
    const dataToFilter = selectDataByNftName();
    return dataToFilter.filter(item => item.client_address === walletAddress || walletAddress === '');
  };

  const onQuestListing = () => {
    setShowLoader(true);
    let sourceToken = axios.CancelToken.source();
    getQuestOrders(raw_data(walletAddress), sourceToken)
      .then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          const karrierPigeons = responseData.filter(data => data.name === "Karrier Pigeons");
          const sacabam = responseData.filter(data => data.name === "Sacabam");
          const novagen = responseData.filter(data => data.name === "Novagen");
          const combinedOrders = combineOrders(responseData);
          setQuestListing(combinedOrders.map((data, i) => ({ ...data, index_id: i + 1 })));
          setKarrierListing(karrierPigeons.map((data, i) => ({ ...data, index_id: i + 1 })));
          setSacabamListing(sacabam.map((data, i) => ({ ...data, index_id: i + 1 })));
          setNovagenListing(novagen.map((data, i) => ({ ...data, index_id: i + 1 })));
          setShowLoader(false);
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });

    return () => sourceToken.cancel();
  };

  useEffect(() => {
    return onQuestListing();
  }, []);

  const responseFormate = (data) => {
    return (
      data &&
      data.slice(0, 50).map((item, index) => {
        return (
          item && {
            key: index,
            no: (
              <div className="indexCount yellowGradient XMdText fontBold">
                #{item.index_id}
              </div>
            ),
            client_address: (
              <div className="dFlex alignBaseline gap20 walletAddress justifyCenter">
                <span className="XMdText yellowGradient fontBold">
                  {item.client_address &&
                    shorten_address(item.client_address, 15, 15)}
                </span>
                <div className="dFlex alignCenter gap10">
                  <span
                    className="svgIcon copy"
                    onClick={() => copyContent(item.client_address)}
                  >
                    <CopyIcon />
                  </span>
                  <span
                    className="svgIcon lift"
                    onClick={() =>
                      window.open(
                        `${explorerAccountURL}${item.client_address}`,
                        "_blank"
                      )
                    }
                  >
                    <ArrowTilt />
                  </span>
                </div>
              </div>
            ),
            order_count: (
              <div className="orderCount yellowGradient XMdText textCenter fontBold">
                {item.order_count}
              </div>
            ),
          }
        );
      })
    );
  };

  return (
    <>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <TableComponent
          data={responseFormate(getFilteredData())}
          columns={columns}
          pagination={false}
        />
      )}
    </>
  );
};

export default LeaderlistComponent;