export const raw_data = (walletAddress) => {
    return {
        merchant_id: 7,
        search_string: walletAddress
    }
}
export const columns = [
    {
        title: "Position",
        dataIndex: "no",
        key: "no",
    },
    {
        title: "Wallet Address",
        dataIndex: "client_address",
        key: "client_address",
    },
    {
        title: "Points",
        dataIndex: "order_count",
        key: "order_count",
    },
];