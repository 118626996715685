/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GenerateIcon,
  DfinityFoundationLogo,
  SuiFoundationLogo,
  GrantIcon,
  LocationIcon,
  PlayButtonIcon,
  GiftIcon,
  PlaystoreMono,
  AppleMono,
  CalenderIcon,
  LeaderboardIcon,
  ArrowTilt,
} from "assets/svg";
import SingaporeVideo from "assets/img/videoplayback.mp4";
import {
  networkMainUrl,
  packageMainId,
  toastMsg,
  useGlobalMerchant,
} from "utils/helper";
import HeaderComponent from "components/Layout/HeaderComponent";
import ButtonComponent from "components/FormsComponent/ButtonComponent";
import ModalComponent from "components/FormsComponent/ModalComponent";
import FooterComponent from "components/Layout/FooterComponent";
import SliderComponent from "components/SliderComponent";
import QRComponent from "components/QRComponent";
import { requiredField, nftOffers } from "./constant";

function DashboardComponent() {
  const history = useHistory();
  const [showQrCode, setShowQrCode] = useState(false);
  const [showGrantVideo, setShowGrantVideo] = useState(false);
  const [onVerifyContent, setOnVerifyContent] = useState(false);
  const [currentNftName, setCurrentNftName] = useState("");
  const [formData, setFormData] = useState({
    ...requiredField,
  });

  const { setSuccessContent } = useGlobalMerchant();
  const [counter, setCounter] = useState(90); // Counter state

  const onGenerateLink = () => {
    const randomKey = Math.floor(100000 + Math.random() * 900000);
    setFormData({
      ...requiredField,
      order_id: randomKey,
    });
    setShowQrCode(true);
    setOnVerifyContent(true);
  };

  const onRegenerateCode = () => {
    setCounter(90);
    setOnVerifyContent(true);
  };

  const selectAnnDateByName = () => {
    switch (currentNftName) {
      case "Karrier Pigeons":
        return "12 Sep'24";
      case "Sacabam":
        return "23 Sep'24";
      case "Novagen":
        return "1 Oct'24";
      default:
        return "1 Oct'2024";
    }
  };

  const onVerifyPayment = async () => {
    const data = {
      method: "suix_queryTransactionBlocks",
      jsonrpc: "2.0",
      params: [
        {
          filter: {
            InputObject: packageMainId,
          },
          options: {
            showEffects: true,
            showInput: true,
          },
        },
        null,
        1,
        true,
      ],
      id: "1",
    };
    try {
      axios
        .post(networkMainUrl, data)
        .then(({ data }) => {
          const { result } = data;
          if (result) {
            const { data } = result;
            if (
              data &&
              data[0] &&
              data[0].transaction &&
              data[0].transaction.data
            ) {
              const getContent = data[0].transaction.data;
              const inputs = getContent.transaction.inputs;
              // const inputsLength = inputs.length;
              // const lastOrderIdIndex = inputsLength - 4; // 4th from last for orderId
              // const lastAddressIndex = inputsLength - 2; // 2nd from last for address
              const inputsLength = inputs.length;
              const lastOrderIdIndex = inputsLength - 1; // 4th from last for orderId
              const lastAddressIndex = inputsLength - 3; // 2nd from last for address
              if (
                inputsLength >= 4 &&
                inputs[lastOrderIdIndex].value ==
                formData.order_id.toString() &&
                inputs[lastAddressIndex].value == formData.merchant_address
              ) {
                const getAnnDate = selectAnnDateByName(currentNftName);
                setSuccessContent({
                  digest: data[0].digest,
                  customer_address: data[0].transaction.data.sender,
                  nft_name: currentNftName,
                  order_id: formData.order_id,
                  winner_ann_date: getAnnDate
                });
                toastMsg("Successfuly placed!");
                setOnVerifyContent(false);
                history.push("/success");
              }
            }
          }
        })
        .catch((error) => {
          setOnVerifyContent(false);
          toastMsg("OOPS! The SUI network may be down");
        });
    } catch {
      setOnVerifyContent(false);
      toastMsg("OOPS! The SUI network may be down");
    }
  };

  useEffect(() => {
    let intervalId = null;

    if (onVerifyContent) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          onVerifyPayment();
          setCounter((prevCounter) => prevCounter - 1); // Increment counter
        } else {
          clearInterval(intervalId);
          setOnVerifyContent(false); // Stop verification
          toastMsg("Verification attempt limit reached.");
        }
      }, 1500);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [onVerifyContent, counter]);

  const RenderAppContent = () => (
    <div className="downloadApps dFlex alignCenter gap20 mt20">
      <p className="NmText">Download BlockBolt App</p>
      <div className="downloadLabel dFlex alignCenter gap10">
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.blockbolt.suiwallet&pli=1"
          }
          target="_blank"
          rel="noreferrer"
          title="Play Store"
        >
          <PlaystoreMono />
        </a>
        <a
          href={
            "https://apps.apple.com/in/app/blockbolt-sui-wallet/id6463131577"
          }
          target="_blank"
          rel="noreferrer"
          title="App Store"
        >
          <AppleMono />
        </a>
      </div>
    </div>
  );

  const PromotionSection = () => (
    <Row gutter={10}>
      <Col
        span={12}
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 12 }}
      >
        <div className="left dFlex gap15 alignCenter justifyCenter wFull">
          <p className="MdText dFlex alignCenter">
            <GrantIcon />
            Received Grant
          </p>
          <div className="content dFlex alignCenter">
            <a
              href={"https://sui.io/"}
              target="_blank"
              rel="noreferrer"
              title="Sui Foundation"
            >
              <SuiFoundationLogo />
            </a>
            <span className="separator"></span>
            <a
              href={"https://dfinity.org/"}
              target="_blank"
              rel="noreferrer"
              title="DFINITY Foundation"
            >
              <DfinityFoundationLogo />
            </a>
          </div>
        </div>
      </Col>
      <Col
        span={12}
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 12 }}
      >
        <div className="right dFlex alignCenter justifyCenter wFull">
          <p className="MdText dFlex alignCenter">
            <LocationIcon />
            Blockbolt At
          </p>
          <div className="content dFlex alignCenter">
            <span className="separator"></span>
            <span className="NmText fontSemiBold ">
              Sui Builder House, Singapore
            </span>
            <ButtonComponent
              btnLight
              onClick={() => setShowGrantVideo(true)}
            >
              Watch Video <PlayButtonIcon />
            </ButtonComponent>
          </div>
        </div>
      </Col>
      <Col span={24} className="gutter-row textRight">
        <ButtonComponent
          className="mt20"
          yellowBtn
          onClick={() => history.push("/leaderboard")}
        >
          <span>
            <LeaderboardIcon
              className="me5"
              style={{ width: "11", height: "14" }}
            />
          </span>
          Leaderboard
        </ButtonComponent>
      </Col>
    </Row>
  );

  const OfferSection = ({ name, endDate, startDate, winners, winnerAnn, winnerLink, winnerAnnText, onClick }) => (
    <div className="offerWrapper">
      <Row gutter={{ xs: 18, sm: 18, md: 18, lg: 0 }} justify="space-between" align="center">
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={10}>
          <SliderComponent name={name} />
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={14}>
          <div className="left textCapitalize">
            <h1 className="LgText">{`'${name}' NFT—Yours to `}<span className="LgText">Win!</span></h1>
            <p className="NmText mt10 mb20">
              Enroll now for a chance to win this <span className="NmText fontMedium">exclusive NFT!<br /></span>
              Don’t let this rare opportunity slip through your fingers!
            </p>
            <div className="mb20 dateWrapper">
              <p className="NmText dFlex alignCenter gap10">
                <CalenderIcon />
                {startDate ? `Starts on - ` : `Ends on - `}
                <span className="MdText">{startDate || endDate}</span>
              </p>
            </div>
            <div className="gift dFlex alignStart gap20 mb30">
              <GiftIcon />
              <p className="MdText">
                {winners} random winners. <br />
                NFT for each.
              </p>
            </div>
            {winnerAnnText && (
              <h1 className="LgText"><span className="LgText">{winnerAnnText}</span></h1>
            )}
            {!winnerAnnText && !winnerAnn && endDate && (
              <ButtonComponent yellowBtn onClick={onClick}>
                Enroll Today - It’s Free!
                <GenerateIcon />
              </ButtonComponent>
            )}
            {winnerAnn && (
              <div className="dFlex alignCenter gap10">
                <h1 className="LgText"><span className="LgText">Winner Announced! </span></h1>
                <a target='_blank' href={winnerLink} className="NmText fontSemiBold dFlex alignBaseline gap5 alignCenter justifyCenter textUnderline">View<ArrowTilt /></a>
              </div>
            )}
            {!winnerAnn && (
              <RenderAppContent />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <ToastContainer />
      <main className="mainSection">
        <div className="container">
          <div className="mainSectionbody">
            <HeaderComponent />
            {nftOffers && nftOffers.map((offer) => (
              <OfferSection
                key={offer.name}
                name={offer.name}
                endDate={offer.endDate}
                startDate={offer.startDate}
                winners={offer.winners}
                winnerAnn={offer.winnerAnn}
                winnerLink={offer.winnerLink}
                winnerAnnText={offer.winnerAnnText}
                onClick={() => {
                  setCurrentNftName(offer.name);
                  onGenerateLink();
                }}
              />
            ))}
          </div>
        </div>
        <div className="container">
          <div className="mainSectionFooter mt10 mb20">
            <PromotionSection />
          </div>
        </div>
      </main>
      <FooterComponent />
      <ModalComponent
        openable={showGrantVideo}
        closable={() => setShowGrantVideo(false)}
        extraClass="iframewrapper"
      >
        <iframe
          src={SingaporeVideo}
          width={"100%"}
          height={"500px"}
          style={{ border: 0 }}
        />
      </ModalComponent>
      <ModalComponent
        openable={showQrCode}
        closable={() => {
          setOnVerifyContent(false);
          setShowQrCode(false);
          setCounter(90);
        }}
      >
        <QRComponent
          qrContent={formData}
          showQrCode={showQrCode}
          counter={counter}
          currentNftName={currentNftName}
          onRegenerateCode={onRegenerateCode}
        />
      </ModalComponent>
    </>
  );
}

export default DashboardComponent;
