import { Image } from 'antd'
import { ArrowTilt } from 'assets/svg';
import slider_one from "assets/img/slider_1.jpg";
import slider_two from "assets/img/slider_2.jpg";
import slider_three from "assets/img/slider_3.jpg";
import slider_four from "assets/img/slider_4.jpg";
import slider_five from "assets/img/slider_5.jpg";
import slider_six from "assets/img/slider_6.jpg";
import slider_seven from "assets/img/slider_7.jpg";
import sacabam_611 from "assets/img/Sacabam_611.jpg";
import sacabam_211 from "assets/img/Sacabam_211.jpg";
import sacabam_1419 from "assets/img/Sacabam_1419.jpg";
import sacabam_563 from "assets/img/Sacabam_563.jpg";
import sacabam_381 from "assets/img/Sacabam_381.jpg";
import novagen_2413 from "assets/img/novagen_2413.jpg";
import novagen_2019 from "assets/img/novagen_2019.jpg";
import novagen_421 from "assets/img/novagen_421.jpg";
import novagen_783 from "assets/img/novagen_783.jpg";
import novagen_2679 from "assets/img/novagen_2679.jpg";
import CarouselComponent from 'components/FormsComponent/CarouselComponent';
import "./style.css";

const SliderComponent = ({ name }) => {

    const sliderData = {
        "Karrier Pigeons": [
            { img: slider_one, id: "#6164", url: "https://www.tradeport.xyz/sui/collection/karrier-pigeons?bottomTab=trades&tab=items&tokenId=0xe528502e26f56062e3f28eef2253bc4696d3f6c92bfadbe5fc4bb2ea208f906e" },
            { img: slider_two, id: "#6065", url: "https://www.tradeport.xyz/sui/collection/karrier-pigeons?bottomTab=trades&tab=items&tokenId=0xb11de41cf25d930aded45f382ae75750e81063d39c4149450f8feb8d45c85b5a" },
            { img: slider_three, id: "#585", url: "https://www.tradeport.xyz/sui/collection/karrier-pigeons?bottomTab=trades&tab=items&tokenId=0x58444c60f1488d93e69b884b9f6140d5e96de80e5571b133f156c893e7b85304" },
            { img: slider_four, id: "#971", url: "https://www.tradeport.xyz/sui/collection/karrier-pigeons?bottomTab=trades&tab=items&tokenId=0x56e4d23d62ebe4d2d36ffba2709b2655f3aec6f9bcf652d566be9f02e460186b" },
            { img: slider_five, id: "#2918", url: "https://www.tradeport.xyz/sui/collection/karrier-pigeons?bottomTab=trades&tab=items&tokenId=0x461bf5a8e9666b7f0cf73522301b3f7c08ed3321e34d710013bbc43b36ded03f" },
            { img: slider_six, id: "#7122", url: "https://www.tradeport.xyz/sui/collection/karrier-pigeons?bottomTab=trades&tab=items&tokenId=0x1e7293332fab2672af2d1dab11f6cda6fcda6f6b15718f0afb1655f3c6579fca" },
            { img: slider_seven, id: "#703", url: "https://www.tradeport.xyz/sui/collection/karrier-pigeons?bottomTab=trades&tab=items&tokenId=0x18cee5c05f793eac6e8a93d98a19e8cf775c0d03d5f3eeb2b68d7cfc9fd2b7df" }
        ],
        "Sacabam": [
            { img: sacabam_563, id: "#563", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&slug=sacabam&tokenId=0xc052ac5e7199ed81f5560086fb63098b20464a684995e6066572dbaffcd060ce" },
            { img: sacabam_381, id: "#381", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&slug=sacabam&tokenId=0x6121e20cb1652b5dd8b4b588819df9c806445e078ec323ca1c8b928404a64f22" },
            { img: sacabam_211, id: "#211", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&slug=sacabam&tokenId=0xa93547bcd20bbc89ae47bc2dea53e1a420d2b40831fe37b9bf6bffb6c08cc870" },
            { img: sacabam_1419, id: "#1419", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&slug=sacabam&tokenId=0xb9d8676fba9fa7966c6aa8fcce37459d9300999584995208638637926b5ac5fc" },
            { img: sacabam_611, id: "#611", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&slug=sacabam&tokenId=0x9cd8c67b5808af4d3b635d33ceac838e77472fe9b77fa0d98a7eb4c2cba4ccda" },
        ],
        "Novagen": [
            { img: novagen_2413, id: "#2413", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&tokenId=0xdc6004f1179667d40ea3b05dd5d8159a5a4045cc0cc928a0a487613e652003d6&slug=novagen" },
            { img: novagen_2019, id: "#2019", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&slug=novagen&tokenId=0x753f3e7404acac2fa640e376825755577320ae560ac3c27d4e029a27e79e865b" },
            { img: novagen_421, id: "#421", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&slug=novagen&tokenId=0x42180a21f656d39e85db5d5c0b1e3b23772dd5d18f1dba33d0846340bc805a1b" },
            { img: novagen_783, id: "#783", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&slug=novagen&tokenId=0x2a379e8c64e5f8b775c9545901ae8479490dd5a12001ada231bae3076350dd00" },
            { img: novagen_2679, id: "#2679", url: "https://www.tradeport.xyz/sui/0xe4352f2e7dd77945d7ecaec3b1a7b9d6d6d7ce7bbadc3660a7a5cb613f601139?tab=items&slug=novagen&tokenId=0x36e992e7cec192c2d1c83cf5d410a5cbff5df603903b2bdb588b0b500b0644b1" },
        ]
    };

    const renderSlides = (data) => {
        return data.map((item, index) => (
            <div key={index} className="right hFull dFlex alignCenter justifyCenter">
                <img src={item.img} alt="suiCoinImage" />
                <div className="imgDetails textCenter">
                    <h1 className="MdText semiBold mb5">{name}</h1>
                    <p className="LgText semiBold mb20">{item.id}</p>
                    <a target='_blank' href={item.url} className="NmText fontSemiBold dFlex alignBaseline gap5 alignCenter justifyCenter textUnderline">View<ArrowTilt /></a>
                </div>
            </div>
        ));
    }

    const onRenderContent = () => {
        const data = sliderData[name];
        if (!data) return null;
        return (
            <div className='nftSlider hFull'>
                <CarouselComponent autoStatus={true}>
                    {renderSlides(data)}
                </CarouselComponent>
            </div>
        );
    }

    return (
        <>{onRenderContent()}</>
    );
}

export default SliderComponent