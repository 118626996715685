import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  errorPageBody: {
    position: "relative",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    zIndex: 2,
    "& .errorPageBg": {
      fontSize: "40vw",
      lineHeight: 0,
      fontWeight: "bold",
      position: "fixed",
      color: "var(--yellowOpacity10)",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: "-1 !important",
      opacity: "0.5",
    },
    "& .errorPageInfo": {
      "& p": {
        "& span": {
          color: "var(--yellow)",
        },
      },
      "& .logo svg": {
        width: "325px",
        height: "100%",
      },
      "& .catGif": {
        width: "150px",
        position: "relative",
        borderRadius: "50%",
        border: "3px solid var(--yellow)",
      },
    },
  },
});
