import {
    MerchantOrders, MerchantQuest,
} from "utils/URLS";
import { postWithoutHeader, putWithoutHeader } from "utils/v2/api";

export const postMerchantOrders = (data) =>
    postWithoutHeader(`${MerchantOrders}`, data);
export const putMerchantOrders = (key, data) =>
    putWithoutHeader(`${MerchantOrders}/${key}`, data);
export const getQuestOrders = (data, sourceToken) =>
    postWithoutHeader(`${MerchantQuest}`, data, sourceToken);