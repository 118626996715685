import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { siteRecaptchaKey } from 'utils/env';
import SuccessComponent from "components/SuccessComponent";

const Success = () => {
  const history = useHistory();

  useEffect(() => {
    if (history.action == "POP") {
      history.push('/');
    }
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteRecaptchaKey}>
      <SuccessComponent />
    </GoogleReCaptchaProvider>
  );
};

export default Success;
