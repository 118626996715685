import { useCallback, useEffect, useState } from "react";
import { Image } from "antd";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  ArrowTilt,
  BBLogo,
  CalenderIcon,
  CopyIcon,
  LeaderboardIcon,
  PointingArrowIcon,
  YSepIcon,
} from "assets/svg";
import thumbsUp from "assets/img/thumbsUp.gif";
import twitterImg from "assets/img/twitterImg.png";
import bbLogoShadow from "assets/img/bbLogoShadow.svg";
import boltIcon from "assets/img/boltIcon.svg";
import { questURL } from "utils/env";
import {
  copyContent,
  explorerURL,
  shorten_address,
  useGlobalMerchant,
} from "utils/helper";
import FooterComponent from "components/Layout/FooterComponent";
import ButtonComponent from "components/FormsComponent/ButtonComponent";
import { onStoreData } from "./constant";
import "./style.css";

const SuccessComponent = ({ googleReCaptchaProps }) => {
  const { successContent } = useGlobalMerchant();
  const [token, setToken] = useState("");
  const history = useHistory();

  const onRecaptchaFailed = () => {
    Object.keys(successContent).length > 0 && getToken();
  };

  const getToken = useCallback(async () => {
    if (googleReCaptchaProps && googleReCaptchaProps.executeRecaptcha) {
      try {
        const _token = await googleReCaptchaProps.executeRecaptcha("homepage");
        setToken(_token);
      } catch (error) {
        // console.error('Error while executing recaptcha:', error);
      }
    } else {
      // console.error('googleReCaptchaProps.executeRecaptcha is not available.');
    }
  }, [googleReCaptchaProps]);

  useEffect(() => {
    if (Object.keys(successContent).length > 0) {
      getToken();
    }
  }, [getToken]);

  useEffect(() => {
    if (token) {
      onStoreData(successContent, token, onRecaptchaFailed);
    }
  }, [token]);

  return (
    <>
      <ToastContainer />
      <div className="boltIcon">
        <img src={boltIcon} alt="" />
      </div>
      <div className="bbLogoShadow">
        <img src={bbLogoShadow} alt="" />
      </div>
      <main className="mainSection">
        <div className="container">
          <div className="mainSectionbody">
            <div className="successSection textCenter">
              <div className="successHeader ">
                <div className="textCenter">
                  <a href={questURL}>
                    <BBLogo />
                  </a>
                </div>
                <Image src={thumbsUp} preview={false} className="mb30 mt30" />
              </div>
              <div className="successBody">
                <h1 className="XLgText textCapitalize mb10 yellowGradient">
                  Thank you for your Participation!
                </h1>
                <p className="MdText mb20">
                  Your enrollment number is
                  <span className="textCapitalize">
                    {" "}
                    {!!successContent.order_id && successContent.order_id}{" "}
                  </span>
                  for
                  <span className="textCapitalize">
                    {" "}
                    '{!!successContent.nft_name &&
                      successContent.nft_name}'{" "}
                  </span>
                  NFT.
                </p>
                {!!successContent.digest && (
                  <div className="txId">
                    <p className="NmText inlineFlex gap10 alignCenter justifyCenter mb20">
                      Transaction Link:
                      <span className="fontSemiBold">
                        {shorten_address(successContent.digest)}
                      </span>
                      <span
                        className="svgIcon dFlex copyTxId"
                        onClick={() =>
                          copyContent(`${explorerURL}${successContent.digest}`)
                        }
                      >
                        <CopyIcon />
                      </span>
                      <span
                        className="svgIcon"
                        onClick={() =>
                          window.open(
                            `${explorerURL}${successContent.digest}`,
                            "_blank"
                          )
                        }
                      >
                        <ArrowTilt />
                      </span>
                    </p>
                  </div>
                )}
                <div className="mb20 dateWrapper">
                  <p className="NmText dFlex alignCenter gap10 justifyCenter">
                    <CalenderIcon />
                    Don’t miss it, Winner announced on <span className="MdText">{successContent.winner_ann_date}</span>
                  </p>
                </div>
              </div>
              <div className="seperator">
                <YSepIcon />
              </div>
              <div className="successFooter mt20">
                <div className="dFlex alignCenter shareWrapper justifyCenter mb20 mt20 gap30 flexWrap">
                  <div className="shareWrapperImg">
                    <img src={twitterImg} alt="Twitter-image" />
                  </div>
                  <div className="shareWrapperContent">
                    <span className="NmText mb20">Share on </span>
                    <a
                      className="yLinkText"
                      href="https://x.com/intent/post?text=%E2%9A%A1+I+just+tried+%23BlockBolt+Checkout+in+%23Winsome+Campaign%E2%80%94Join+now+to+win+%23NFTs%21%0A%0A%F0%9F%91%8D+Enroll+now+at+quest.blockbolt.io+%F0%9F%8E%89%0A%0A%23NFTGiveaway+%23SuiNetwork+%23SUI"
                      title="Share on Twitter"
                      target="_blank"
                    >
                      <span className="share-btn-icon"></span>
                      <span className="share-btn-text">Twitter</span>
                      <div className="pointingArrowGif">
                        <PointingArrowIcon />
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <span className="NmText">
                    Thank you for testing the Blockbolt Payment protocol. For
                    any suggestions or questions, <br />
                    please email us at{" "}
                  </span>
                  <a
                    href="mailto:support@blockbolt.io?subject=Blockbolt%20Payment%20Protocol%20Inquiry"
                    className="yLinkText"
                  >
                    support@blockbolt.io
                  </a>
                  .
                </div>
                <div className="dFlex alignCenter justifyCenter gap5 textCenter mt30">
                  <ButtonComponent yellowBtn onClick={() => history.push("/")}>
                    Back to Quest
                  </ButtonComponent>
                  <ButtonComponent
                    yellowBtn
                    onClick={() => history.push("/leaderboard")}
                  >
                    <span>
                      <LeaderboardIcon
                        className="me5"
                        style={{ width: "11", height: "14" }}
                      />
                    </span>
                    Leaderboard
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterComponent />
    </>
  );
};

export default withGoogleReCaptcha(SuccessComponent);
