import React from "react";
import boltIcon from "assets/img/boltIcon.svg";
import bbLogoShadow from "assets/img/bbLogoShadow.svg";
import { questURL } from "utils/env";
import { BBLogo } from "assets/svg";
import "./style.css";

const HeaderComponent = ({ onReloadContent }) => {
  const getpathname = window.location.pathname;
  return (
    <>
      <div className="boltIcon">
        <img src={boltIcon} alt="" />
      </div>
      <div className="bbLogoShadow">
        <img src={bbLogoShadow} alt="" />
      </div>
      <header className="headerSection">
        <a href={questURL}><BBLogo /></a>
        <h1 className="yellowGradient dBlock">Decentralized Payment</h1>
        {getpathname == "/" && <span className="yellowGradient dBlock"> Winsome Quest Rewards!</span>}
      </header >
    </>
  );
};

export default HeaderComponent;
