import React from "react";
import "./style.css";
const FooterComponent = () => {
  return (
    <section className="footerSection textCenter">
      <div className="container">
      <p className="XSmText">Payments are processed by BlockBolt Inc. All sales final, refunds handled by the merchant.
      </p>
      <span className="XSmText">© BlockBolt Inc. All rights reserved.</span>
      </div>
    </section>
  );
};

export default FooterComponent;
