import { Table } from "antd";
import React from "react";

const TableComponent = ({
  data,
  columns,
  pagination,
  ...rest
}) => {
  return (
    <Table
      {...rest}
      dataSource={data}
      columns={columns}
      pagination={pagination}
    />
  );
};

export default TableComponent;
