import { Helmet } from "react-helmet";
import DashboardComponent from "components/DashboardComponent";
import "./style.css";

function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Winsome :: Blockbolt Quest for Rewards!</title>
      </Helmet>
      <DashboardComponent />
    </>
  );
}

export default Dashboard;
