import { Select } from 'antd'
import React from 'react'

const SelectComponent = ({ defaultValue, options, handleChange }) => {
    return (
        <Select
            defaultValue={defaultValue}
            style={{ width: 200 }}
            onChange={handleChange}
            options={options}
        />
    )
}

export default SelectComponent