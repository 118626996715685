import React, { useEffect, useState } from "react";
import { Image } from "antd";
import classNames from "classnames";
import QRCode from "qrcode.react";
import MonogramIcon from "assets/img/monogram-golden.svg";
import AppleStoreIcon from "assets/img/appstore.svg";
import PlayStoreIcon from "assets/img/playstore.svg";
import { QrCanvasBorder } from "assets/svg";
import ButtonComponent from "components/FormsComponent/ButtonComponent";
import { useStyles } from "./style";

const QRComponent = ({ qrContent, showQrCode, counter, currentNftName, onRegenerateCode }) => {
    const [qrValue, setQrValue] = useState("");
    const classes = useStyles();

    useEffect(() => {
        if (showQrCode) {
            setQrValue(JSON.stringify(qrContent));
        }
    }, [showQrCode]);

    return (
        <>
            {qrValue !== "" && (
                <div className={classNames(classes.qrCodeWrapper)}>
                    <div className="qrCodeHeader textCenter">
                        <div className="networkWrapper">
                            <p className="SmText fontSemiBold">Mainnet - SUI</p>
                            <div className="netPulse">
                                <div className="netPulseDot"></div>
                                <div className="netPulseRing"></div>
                            </div>
                        </div>
                        <span className="MdText fontMedium mb10 mt20 dBlock textCenter textUpper"><span className="fontBold">'{currentNftName}'</span> NFT</span>
                        <span className="NmText fontNormal mb20 dBlock textCenter">Enroll now - <span className="textUpper fontBold">FREE!</span></span>
                    </div>
                    {
                        counter == 0 ? (
                            <ButtonComponent className="mb20" btnSm yellowBtn onClick={() => onRegenerateCode()}>Refresh the QR Code</ButtonComponent>
                        ) : (
                            <div className="qrCodeBody mb20">
                                <QRCode
                                    value={qrValue}
                                    size={173}
                                    imageSettings={{
                                        src: MonogramIcon,
                                        x: undefined,
                                        y: undefined,
                                        height: 25,
                                        width: 25,
                                        excavate: true,
                                    }}
                                />
                                <div className="qrCanvasBorder">
                                    <QrCanvasBorder />
                                </div>
                            </div>
                        )
                    }
                    <p className="XSmText mb5">Expires in <span className="SmText fontSemiBold">{counter} seconds</span></p>
                    <div className="qrCodeFooter">
                        <h2 className="NmText textCenter fontSemiBold">Scan QR With Blockbolt App</h2>
                        <div className="appDownloadWrapper dFlex alignCenter gap10 mt10">
                            <div className="appDownloadRight dFlex gap5">
                                <a
                                    href={
                                        "https://play.google.com/store/apps/details?id=com.blockbolt.suiwallet&pli=1"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Image src={PlayStoreIcon} preview={false} />
                                </a>
                                <a
                                    href={
                                        "https://apps.apple.com/in/app/blockbolt-sui-wallet/id6463131577"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Image src={AppleStoreIcon} preview={false} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div >
            )}
        </>
    );
};

export default QRComponent;
