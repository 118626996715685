import { postMerchantOrders } from "apiServices/app";
import { EncryptionPassword, encryptObject } from "utils/encryptDecrypt";
import { dateAndTimeFormat, merchant_waddress, recaptchaFailedText, toastMsg } from "utils/helper";

export const onStoreData = async (successContent, tokenValue, onRecaptchaFailed) => {
    const getDateAndTime = await dateAndTimeFormat();
    const newObject = {
        amount: '0',
        call_back_url: null,
        client_address: successContent.customer_address,
        coin: "SUI",
        coin_amount: "0",
        date_time: getDateAndTime,
        description: null,
        digest: successContent.digest,
        image_path: null,
        merchant_address: merchant_waddress,
        name: successContent.nft_name,
        network: "Mainnet",
        payment_id: successContent.order_id,
        order_type: "Quest payment",
        status: "completed",
        recaptchaToken: tokenValue
    };
    const enctyptData = await encryptObject(newObject, EncryptionPassword);
    try {
        postMerchantOrders({ "raw_data": enctyptData })
            .then((response) => {
                const responseData = response.data || response;
                if (responseData && responseData.order_id) {
                    toastMsg("Successfully placed!");
                }
            })
            .catch((error) => {
                if (error && error.message && error.message == recaptchaFailedText) {
                    onRecaptchaFailed();
                } else {
                    toastMsg(error && error.message);
                }
            });
    } catch (error) { }
};