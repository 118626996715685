import { ConfigProvider } from "antd";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { WalletKitProvider } from "@mysten/wallet-kit";
import { GlobalMerchantProvider } from "utils/helper";
import NotRecognize from "pages/NotRecognize";
import LeaderBoard from "pages/LeaderBoard";
import Dashboard from "pages/Dashboard";
import Success from "pages/Success";
import "./assets/global.css";

function App() {
  return (
    <BrowserRouter>
      <GlobalMerchantProvider>
        <ConfigProvider>
          <WalletKitProvider
            features={["sui:signTransactionBlock"]}
            enableUnsafeBurner
          >
            <Switch>
              <Route exact path="/leaderboard" component={LeaderBoard} />
              <Route exact path="/success" component={Success} />
              <Route exact path="/" component={Dashboard} />
              <Route path="*" component={NotRecognize} />
            </Switch>
          </WalletKitProvider>
        </ConfigProvider>
      </GlobalMerchantProvider>
    </BrowserRouter>
  );
}
export default App;