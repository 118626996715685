import React from "react";
import classNames from "classnames";
import { Tooltip } from "antd";
import { TooltipIcon } from "assets/svg";
import LabelComponent from "../LabelComponent";
import { useStyles } from "./style";

const TextBoxComponent = ({
  error = "",
  label = "",
  subTitle = "",
  mandatory,
  title = "Textbox",
  className,
  extraClass,
  labelExtraIcon,
  loading,
  style,
  theme,
  tooltip,
  onChange,
  tooltipTitle,
  ...rest
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const { target } = event;
    const { value, name, ...otherAttributes } = target;
    const sanitizedValue = value.replace(/[\\{}=<>$#"]+/g, "");
    const newTarget = { ...otherAttributes, value: sanitizedValue, name: name };
    onChange({ ...event, target: newTarget });
  };

  return (
    <div
      className={classNames(classes.formGrp, extraClass, "formFields")}
      style={style}
    >
      <div className="fgBox d-flex flex-column">
        {label && (
          <LabelComponent>
            {label} {mandatory && <span className="danger">*</span>}
            {labelExtraIcon}
          </LabelComponent>
        )}
        {subTitle && <small>{subTitle}</small>}
        <>
          <input
            maxLength={250}
            aria-label={title ? title : label}
            aria-labelledby={title ? title : label}
            title={title ? title : label}
            className={classNames(className)}
            autoComplete="off"
            onChange={handleChange}
            {...rest}
          />
          {tooltip && (
            <Tooltip title={tooltipTitle}>
              <span className="tooltipIcon">
                <TooltipIcon />
              </span>
            </Tooltip>
          )}
        </>
      </div>
      {error && <p className="danger">{error}</p>}
    </div>
  );
};
export default TextBoxComponent;
