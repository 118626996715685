import { createUseStyles } from "react-jss";
import mediaQuery from "assets/breakpoints";
export const useStyles = createUseStyles({
  btn: {
    display: "inline-flex",
    alignItems: "center",
    gap: "5px",
    background: "var(--logoGradient)",
    color: "var(--white)",
    fontSize: "16px",
    padding: "15px 40px",
    border: "none",
    borderRadius: "8px",
    fontWeight: "550",
    letterSpacing: "0.2px",
    cursor: "pointer",
    transition: "0.2s ease-in-out",
    textAlign: "left",
    [mediaQuery.SCREEN_SM_MAX]: {
      padding: "10px 25px",
      fontSize: "14px",
    },
    "&:hover": {
      background: "var(--revLogoGradient)",
    },
  },
  btnLight: {
    background: "var(--whiteOpacity05)",
    "&:hover": {
      background: "var(--whiteOpacity10)",
    },
  },
  yellowBtn: {
    background: "var(--yellow)",
    color: "var(--dark)",
    padding: "12px 36px",
    "&:hover": {
      background: "var(--yellow)",
      opacity: "0.7",
    },
    "& span": {
      "& svg": {
        "& > path": {
          fill: "var(--dark)",
        },
      },
    },
  },
  btnSm: {
    fontSize: "14px",
    padding: "12px 36px",
  },
  successBtn: {
    background: "var(--success)",
    "&:hover": {
      background: "var(--success)",
      opacity: "0.7",
    },
  },
});
