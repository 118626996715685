import { merchant_waddress } from "utils/helper";

export const nftOffers = [
  {
    name: "Novagen",
    endDate: "30 Sep'24",
    winners: 5,
    startDate: null,
    winnerAnnText: null,
    winnerAnn: true,
    winnerLink: "https://docs.google.com/spreadsheets/d/1oxCxy1nprZUujy1psisbDqMYxyaqV_90DhmyxJcvxrk/edit?usp=sharing"
  },
  {
    name: "Sacabam",
    endDate: "19 Sep'24",
    winners: 5,
    startDate: null,
    winnerAnnText: null,
    winnerAnn: true,
    winnerLink: "https://docs.google.com/spreadsheets/d/1slewA5r5dnwGbvZre-WzQ9lJ99CQBoVACNzEPqoLf8E/edit?gid=0#gid=0"
  },
  {
    name: "Karrier Pigeons",
    endDate: "12 Sep'24",
    winners: 7,
    startDate: null,
    winnerAnnText: null,
    winnerAnn: true,
    winnerLink: "https://docs.google.com/spreadsheets/d/1j7YaUPSdiJjI6Oie84N7YLRRG6qXK9BcIQHN6YBkoxY/edit?usp=sharing"
  }
];

export const coinMainList = [
  {
    name: "SUI",
    image: "https://s2.coinmarketcap.com/static/img/coins/64x64/20947.png",
    symbol: "SUI",
  },
  {
    name: "USDC",
    image: "https://suivision.xyz/images/coins/usdc.png",
    symbol: "USDC",
  },
  {
    name: "NAVX",
    image:
      "https://ctiyulirqff3b6evprwjwpr7c7wgimilhugii3cnat72wq3h7ira.arweave.net/FNGKLRGBS7D4lXxsmz4_F-xkMQs9DIRsTQT_q0Nn-iI",
    symbol: "NAVX",
  },
  {
    name: "Scallop",
    image:
      "https://vrr7y7aent4hea3r444jrrsvgvgwsz6zi2r2vv2odhgfrgvvs6iq.arweave.net/rGP8fARs-HIDcec4mMZVNU1pZ9lGo6rXThnMWJq1l5E",
    symbol: "SCA",
  },
  {
    name: "FlowX",
    image: "https://imagedelivery.net/cBNDGgkrsEA-b_ixIp9SkQ/flx.svg/public",
    symbol: "FLX",
  },
  {
    name: "FUD",
    image: "https://imagedelivery.net/cBNDGgkrsEA-b_ixIp9SkQ/fud.png/public",
    symbol: "FUD",
  },
  {
    name: "Sacabam",
    image:
      "https://imagedelivery.net/cBNDGgkrsEA-b_ixIp9SkQ/sacabam.png/public",
    symbol: "SCB",
  },
];

export const coinTestList = [
  {
    name: "SUI",
    image: "https://s2.coinmarketcap.com/static/img/coins/64x64/20947.png",
    symbol: "SUI",
  },
];

export const chainList = [
  {
    name: "Mainnet",
  },
  {
    name: "Testnet",
  },
];

export const requiredField = {
  merchant_address: merchant_waddress,
  merchant_name: "Blockbolt Winsome",
  order_id: 0,
  amount: "0",
  network: "Mainnet",
  blockchain: "SUI",
  coin_name: "SUI",
  coin_type: "0x2::sui::SUI"
};

export const requiredWOAField = {
  merchant_address: "",
  merchant_name: "",
  amount: "",
};

export const requiredWAField = {
  merchant_address: "",
  merchant_name: "",
  amount: "",
};
