import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  qrCodeWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .qrCodeHeader": {
      "& .networkWrapper": {
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: "var(--yellowOpacity10)",
        padding: "5px 20px",
        borderRadius: "30px",
        border: "1px solid var(--yellow)",
        "& p": {
          color: "var(--dark)",
        },
        "& span": {
          color: "var(--yellow)",
        },
        "& .netPulse": {
          height: "26px",
          paddingBottom: "0",
          position: "relative",
          width: "26px",
          "& .netPulseDot": {
            background: "var(--yellow)",
            borderRadius: "50%",
            height: "9px",
            left: "50%",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "9px",
          },
          "& .netPulseRing": {
            animation: "PulsePoint-pulse-OnK 1.5s ease-in-out infinite",
            border: "1px solid var(--yellow)",
            borderRadius: "50%",
            height: "100%",
            opacity: 0,
            width: "100%",
          },
        },
      },
      "& span": {
        color: "var(--dark)",
      },
    },
    "& .qrCodeBody": {
      padding: "20px",
      width: "fit-content",
      border: "1px solid var(--yellow)",
      borderRadius: "10px",
      position: "relative",
      "& .qrCanvasBorder": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        "& svg path": {
          fill: "var(--yellow)",
        },
      },
    },
    "& .qrCodeFooter": {
      "& h2": {
        color: "var(--dark)",
      },
      "& .appDownloadLeft": {
        "& p": {
          color: "var(--darkSilver)",
        },
      },
      "& .appDownloadRight": {
        "& img": {
          width: "110px",
          height: "33px",
          transition: "0.2s all ease-in-out",
          "&:hover": {
            opacity: "0.7",
          },
        },
      },
    },
  },
});
