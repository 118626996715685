import { Modal } from "antd";

const ModalComponent = ({ title, openable, closable, children, extraClass }) => {
    return (
        <Modal
            className={extraClass}
            title={title}
            open={openable}
            onOk={closable}
            onCancel={closable}
            footer=""
        >
            {children}
        </Modal>
    );
};

export default ModalComponent;